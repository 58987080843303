import { Groove, Head } from '@/types/groove'
import { GCode } from '@/types/gCode'
import { ElementaryMove } from './GCode/ElementaryMove'
import { GrooveHelper } from '@/helper/GrooveHelper'
import { CurveIterator } from './CurveIterator'

export class GCodeService {
    private elementaryMove: ElementaryMove
    private curveIterator: CurveIterator

    constructor (elementaryMove: ElementaryMove, curveIterator: CurveIterator) {
      this.elementaryMove = elementaryMove
      this.curveIterator = curveIterator
    }

    public generateGCode (grooves: Array<Groove>, head: Head): GCode {
      const commands: Array<string> = [
        `S${head.moveRate} M3`
      ]
      let command: string

      for (const groove of grooves) {
        for (const partialGroove of GrooveHelper.partialGroove(groove)) {
          console.log(partialGroove)
          this.curveIterator.add(partialGroove)
        }
      }
      for (const [curve, feedRate, moveType] of this.curveIterator.iterateCurve(head)) {
        this.elementaryMove.setDrillFeetRate(feedRate)
        this.elementaryMove.setMoveFeedRate(feedRate)
        if (curve.name === 'CircleSegment') {
          command = this.elementaryMove.circleSegment(curve.end, true, curve.description.radius ?? 0)
        } else {
          if (moveType === 'Down') {
            command = this.elementaryMove.plungeDrill(curve.end)
          } else if (moveType === 'Up') {
            command = this.elementaryMove.pullOutHead()
          } else { // (moveType === 'Horizontal')
            if (curve.start.z > 0) {
              command = this.elementaryMove.quickMove(curve.end)
            } else {
              command = this.elementaryMove.lineSegment(curve.end)
            }
          }
        }

        commands.push(command)
      }
      commands.push(...[ // end program
        'G00 Z50',
        'G00 X0 Y0',
        'M30'
      ])
      return {
        commands
      }
    }
}
