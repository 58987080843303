import { InputContour, InputDrill, InputPerimeter, InputPocket, InputPoint, InputType } from '@/types/inputAction'
import { Action, Contour, Drill, Perimeter, Pocket } from '@/types/action'
import { Point } from '@/types/geometry'

export class ActionFactory {
    private inputDrill?: InputDrill
    private inputContour?: InputContour
    private inputPerimeter?: InputPerimeter
    private inputPocket?: InputPocket
    private actualAction?: InputType
    private isValid: boolean

    constructor () {
      this.inputDrill = undefined
      this.inputContour = undefined
      this.inputPerimeter = undefined
      this.inputPocket = undefined
      this.actualAction = undefined
      this.isValid = false
    }

    public setDrill (drill: InputDrill): boolean {
      const con1 = drill.depth !== undefined
      const con2 = drill.plungingSpeed !== undefined
      const con3 = drill.workDepth !== undefined
      const con4 = drill.point.x !== undefined
      const con5 = drill.point.y !== undefined
      if (con1 && con2 && con3 && con4 && con5) {
        this.isValid = true
      } else {
        this.isValid = false
      }
      this.inputDrill = drill
      this.actualAction = InputType.InputDrill
      return this.isValid
    }

    public setContour (contour: InputContour) {
      const con1 = contour.workDepth !== undefined
      const con2 = contour.depth !== undefined
      const con3 = contour.plungingSpeed !== undefined
      const con4 = contour.workDepth !== undefined
      let con5 = true
      for (const point of contour.points) {
        if (point.x === undefined && point.y === undefined) {
          con5 = false
          break
        }
      }
      if (con1 && con2 && con3 && con4 && con5) {
        this.isValid = true
      } else {
        this.isValid = false
      }
      this.inputContour = contour
      this.actualAction = InputType.InputContour
      return this.isValid
    }

    public setPerimeter (perimeter: InputPerimeter):boolean {
      const con1 = perimeter.depth !== undefined
      const con2 = perimeter.plungingSpeed !== undefined
      const con3 = perimeter.workDepth !== undefined
      const con4 = perimeter.point.x !== undefined
      const con5 = perimeter.point.y !== undefined
      const con6 = perimeter.radius !== undefined
      const con7 = perimeter.workSpeed !== undefined
      if (con1 && con2 && con3 && con4 && con5 && con6 && con7) {
        this.isValid = true
      } else {
        this.isValid = false
      }
      this.inputPerimeter = perimeter
      this.actualAction = InputType.InputPerimeter
      return this.isValid
    }

    public setPocket (pocket: InputPocket) {
      const con1 = pocket.depth !== undefined
      const con2 = pocket.plungingSpeed !== undefined
      const con3 = pocket.workDepth !== undefined
      const con4 = pocket.point.x !== undefined
      const con5 = pocket.point.y !== undefined
      const con6 = pocket.radius !== undefined
      const con7 = pocket.workSpeed !== undefined
      if (con1 && con2 && con3 && con4 && con5 && con6 && con7) {
        this.isValid = true
      } else {
        this.isValid = false
      }
      this.inputPocket = pocket
      this.actualAction = InputType.InputPocket
      return this.isValid
    }

    public create (): Action {
      let action: Action
      switch (this.actualAction) {
        case InputType.InputDrill:
          action = this.mapDrill()
          break
        case InputType.InputPocket:
          action = this.mapPocket()
          break
        case InputType.InputPerimeter:
          action = this.mapPerimeter()
          break
        case InputType.InputContour:
          action = this.mapContour()
          break

        default:
          throw new Error('')
      }
      return action
    }

    private mapDrill (): Drill {
      if (this.inputDrill !== undefined) {
        return {
          depth: this.inputDrill.depth ?? 0,
          plungingSpeed: this.inputDrill.plungingSpeed ?? 0,
          point:
                    this.mapPoint(this.inputDrill.point),
          workDepth: this.inputDrill.workDepth ?? 0,
          name: 'Drill'
        }
      } else {
        throw new Error('')
      }
    }

    private mapContour (): Contour {
      if ((this.inputContour !== undefined)) {
        const points = this.inputContour.points.map(this.mapPoint)
        return {
          depth: this.inputContour.depth ?? 0,
          plungingSpeed: this.inputContour.plungingSpeed ?? 0,
          workDepth: this.inputContour.workDepth ?? 0,
          workSpeed: this.inputContour.workSpeed ?? 0,
          name: 'Contour',
          points
        }
      } else {
        throw new Error('')
      }
    }

    private mapPoint (inputPoint: InputPoint): Point {
      return {
        x: inputPoint.x ?? 0,
        y: inputPoint.y ?? 0,
        z: 0
      }
    }

    private mapPocket (): Pocket {
      if (this.inputPocket !== undefined) {
        return {
          name: 'Pocket',
          depth: this.inputPocket.depth ?? 0,
          length: this.inputPocket.length ?? 0,
          plungingSpeed: this.inputPocket.plungingSpeed ?? 0,
          point: this.mapPoint(this.inputPocket.point),
          radius: this.inputPocket.radius ?? 0,
          width: this.inputPocket.width ?? 0,
          workDepth: this.inputPocket.workDepth ?? 0,
          workSpeed: this.inputPocket.workSpeed ?? 0
        }
      } else {
        throw new Error('')
      }
    }

    private mapPerimeter (): Perimeter {
      if (this.inputPerimeter !== undefined) {
        return {
          name: 'Perimeter',
          depth: this.inputPerimeter.depth ?? 0,
          length: this.inputPerimeter.length ?? 0,
          plungingSpeed: this.inputPerimeter.plungingSpeed ?? 0,
          point: this.mapPoint(this.inputPerimeter.point),
          radius: this.inputPerimeter.radius ?? 0,
          width: this.inputPerimeter.width ?? 0,
          workDepth: this.inputPerimeter.workDepth ?? 0,
          workSpeed: this.inputPerimeter.workSpeed ?? 0
        }
      } else {
        throw Error('')
      }
    }
}
