import { Pocket, Contour, Drill, Perimeter, Action } from '@/types/action'
import { Groove, CurveChain, Head } from '@/types/groove'
import { Curve, Point } from '@/types/geometry'

export class GrooveBuilderService {
    private head: Head

    public constructor (head: Head) {
      this.head = head
    }

    public getGroove (action: Action): Groove {
      let groove: Groove
      switch (action.name) {
        case 'Pocket':
          groove = this.createPocket(action)
          break
        case 'Contour':
          groove = this.createContour(action)
          break
        case 'Drill':
          groove = this.createDrill(action)
          break
        case 'Perimeter':
          groove = this.createPerimeter(action)
          break
      }
      return groove
    }

    private createDrill (drill: Drill): Groove {
      const curve: Curve = {
        start: {
          x: drill.point.x,
          y: drill.point.y,
          z: this.head.actualLocation.z
        },
        end: {
          x: drill.point.x,
          y: drill.point.y,
          z: -drill.depth
        },
        name: 'Point',
        description: {}
      }
      const curveChain: CurveChain = [curve]
      return {
        curveChain,
        width: this.head.width,
        depth: drill.depth,
        workDepth: drill.workDepth,
        plungingSpeed: drill.plungingSpeed,
        workSpeed: this.head.feedRate
      }
    }

    /**
     * bottom, center line segment
     */
    private getStartPoint (rectangle: Pocket | Perimeter, correction: number): Point {
      return {
        x: rectangle.point.x,
        y: rectangle.point.y - (rectangle.width / 2 + correction),
        z: -rectangle.depth
      }
    }

    private getCircleSegmentEnd (startPoint: Point, radius: number, order: number): Point {
      let endPoint: Point
      switch (order) {
        case 1:
          endPoint = {
            x: startPoint.x - radius,
            y: startPoint.y + radius,
            z: startPoint.z
          }
          break
        case 2:
          endPoint = {
            x: startPoint.x + radius,
            y: startPoint.y + radius,
            z: startPoint.z
          }
          break
        case 3:
          endPoint = {
            x: startPoint.x + radius,
            y: startPoint.y - radius,
            z: startPoint.z
          }
          break
        case 4:
          endPoint = {
            x: startPoint.x - radius,
            y: startPoint.y - radius,
            z: startPoint.z
          }
          break
        default:
          throw new Error('')
      }
      return endPoint
    }

    private getLineSegmentEnd (startPoint: Point, rectangle: Pocket | Perimeter, order: number): Point {
      let endPoint: Point
      switch (order) {
        case 1:
          endPoint = {
            x: startPoint.x - rectangle.length / 2 + rectangle.radius,
            y: startPoint.y,
            z: startPoint.z
          }
          break
        case 2:
          endPoint = {
            x: startPoint.x,
            y: startPoint.y + rectangle.width - 2 * rectangle.radius,
            z: startPoint.z
          }
          break
        case 3:
          endPoint = {
            x: startPoint.x + rectangle.length - 2 * rectangle.radius,
            y: startPoint.y,
            z: startPoint.z
          }
          break
        case 4:
          endPoint = {
            x: startPoint.x,
            y: startPoint.y - rectangle.width + 2 * rectangle.radius,
            z: startPoint.z
          }
          break
        case 5:
          endPoint = {
            x: startPoint.x - rectangle.length / 2 + rectangle.radius,
            y: startPoint.y,
            z: startPoint.z
          }
          break
        default:
          throw new Error('')
      }
      return endPoint
    }

    private createCircleSegment (start: Point, end: Point, radius: number): Curve {
      return {
        start,
        end,
        name: 'CircleSegment',
        description: {
          radius: radius
        }
      }
    }

    private createLineSegment (start: Point, end: Point): Curve {
      return {
        start,
        end,
        name: 'Line',
        description: {}
      }
    }

    private createRectangle (pocket: Pocket| Perimeter, startPoint: Point, correction: number): Groove {
      const curveChain: CurveChain = []
      let endPoint: Point
      for (let order = 1; order < 6; order++) {
        endPoint = this.getLineSegmentEnd(startPoint, pocket, order)
        curveChain.push(this.createLineSegment(startPoint, endPoint))
        startPoint = endPoint
        if (pocket.radius !== 0 && order !== 5) {
          endPoint = this.getCircleSegmentEnd(startPoint, pocket.radius + correction, order)
          curveChain.push(this.createCircleSegment(startPoint, endPoint, pocket.radius + correction))
          startPoint = endPoint
        }
      }
      return {
        curveChain,
        depth: pocket.depth,
        width: this.head.width,
        plungingSpeed: pocket.plungingSpeed,
        workDepth: pocket.workDepth,
        workSpeed: pocket.workSpeed
      }
    }

    private createPocket (pocket: Pocket): Groove {
      const correction = -this.head.width / 2
      const startPoint: Point = this.getStartPoint(pocket, correction)
      return this.createRectangle(pocket, startPoint, correction)
    }

    private createPerimeter (perimeter: Perimeter): Groove {
      const correction = this.head.width / 2
      const startPoint: Point = this.getStartPoint(perimeter, correction)
      return this.createRectangle(perimeter, startPoint, correction)
    }

    private createContour (contour: Contour): Groove {
      const curveChain: CurveChain = []
      for (let index = 1; index < contour.points.length; index++) {
        const startPoint = contour.points[index - 1]
        const endPoint = contour.points[index]
        const segment = this.createLineSegment(startPoint, endPoint)
        curveChain.push(segment)
      }
      return {
        curveChain,
        depth: contour.depth,
        width: this.head.width,
        plungingSpeed: contour.plungingSpeed,
        workDepth: contour.workDepth,
        workSpeed: contour.workSpeed
      }
    }
}
