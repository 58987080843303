import { Curve, Point } from '@/types/geometry'

export type MoveType = 'Up' | 'Down' | 'Horizontal' | 'General'

export class CurveHelper {
  static join (actualCurve: Curve, nextCurve: Curve, quickMoveHeight: number): Array<[Curve, MoveType]> {
    const nextCurveMoveType: MoveType = this.getMoveType(nextCurve)
    const resultCurveChain: Array<[Curve, MoveType]> = []
    let isUp = false
    if (actualCurve.end.x !== nextCurve.start.x ||
      actualCurve.end.y !== nextCurve.start.y ||
      actualCurve.end.z !== nextCurve.start.z ||
      nextCurveMoveType === 'Down') {
      console.log('if 1')
      isUp = true
      const up: [Curve, MoveType] = [{
        name: 'Line',
        description: {},
        start: { x: actualCurve.end.x, y: actualCurve.end.y, z: actualCurve.end.z },
        end: { x: actualCurve.end.x, y: actualCurve.end.y, z: quickMoveHeight }
      }, 'Up']
      resultCurveChain.push(up)
    }

    if (actualCurve.end.x !== nextCurve.start.x ||
      actualCurve.end.y !== nextCurve.start.y) {
      console.log('if 2')
      const horizontal: [Curve, MoveType] = [{
        name: 'Line',
        description: {},
        start: { x: actualCurve.end.x, y: actualCurve.end.y, z: quickMoveHeight },
        end: { x: nextCurve.start.x, y: nextCurve.start.y, z: quickMoveHeight }
      }, 'Horizontal']
      resultCurveChain.push(horizontal)
    }

    if (isUp === true) {
      console.log('if 3')
      const endPoint: Point = nextCurveMoveType === 'Down'
        ? { x: nextCurve.end.x, y: nextCurve.end.y, z: nextCurve.end.z }
        : { x: nextCurve.start.x, y: nextCurve.start.y, z: nextCurve.start.z }
      const down: [Curve, MoveType] = [{
        name: 'Line',
        description: {},
        start: { x: nextCurve.start.x, y: nextCurve.start.y, z: quickMoveHeight },
        end: endPoint
      }, 'Down']
      resultCurveChain.push(down)
    }

    if (nextCurveMoveType !== 'Down') {
      console.log('if 4')
      resultCurveChain.push([nextCurve, nextCurveMoveType])
    }
    return resultCurveChain
  }

  private static getMoveType (curve: Curve): MoveType {
    if (curve.start.x === curve.end.x && curve.start.y === curve.end.y) {
      return curve.start.z > curve.end.z ? 'Down' : 'Up'
    }
    return curve.start.z === curve.end.z ? 'Horizontal' : 'General'
  }
}
