
interface InputPoint {
    x?: number,
    y?: number
}

type PointArray = Array<InputPoint>

interface BaseAction {
    depth?: number,
    workDepth?: number,
    plungingSpeed?: number,
}

interface InputDrill extends BaseAction {
    readonly name: 'Drill'
    point: InputPoint,
}

export class EmptyInputDrill implements InputDrill {
    depth?: number | undefined;
    name: 'Drill';
    plungingSpeed?: number | undefined;
    point: InputPoint;
    workDepth?: number | undefined;
    constructor () {
      this.name = 'Drill'
      this.depth = undefined
      this.plungingSpeed = undefined
      this.point = {
        x: undefined,
        y: undefined
      }
    }
}

interface InputContour extends BaseAction {
    readonly name: 'Contour',
    points: PointArray,
    workSpeed?: number,
}

export class EmptyInputContour implements InputContour {
    name: 'Contour';
    points: PointArray;
    workSpeed?: number;
    depth?: number;
    workDepth?: number;
    plungingSpeed?: number;
    constructor () {
      this.name = 'Contour'
      this.points = []
      this.workDepth = undefined
      this.depth = undefined
      this.workSpeed = undefined
      this.plungingSpeed = undefined
    }
}

interface Rectangle extends BaseAction {
    point: InputPoint, // centre
    length?: number, // y-axis
    width?: number, // x-axis
    radius?: number,
    workSpeed?: number
}

interface InputPocket extends Rectangle {
    readonly name: 'Pocket'
}

export class EmptyInputPocket implements InputPocket {
    name: 'Pocket';
    point: InputPoint;
    length?: number;
    width?: number;
    radius?: number;
    workSpeed?: number;
    depth?: number;
    workDepth?: number;
    plungingSpeed?: number;
    constructor () {
      this.name = 'Pocket'
      this.point = {
        x: undefined,
        y: undefined
      }
      this.length = undefined
      this.width = undefined
      this.radius = undefined
      this.workSpeed = undefined
      this.depth = undefined
      this.workDepth = undefined
      this.plungingSpeed = undefined
    }
}

interface InputPerimeter extends Rectangle {
    readonly name: 'Perimeter'
}

export class EmptyInputPerimeter implements InputPerimeter {
    name: 'Perimeter';
    point: InputPoint;
    length?: number;
    width?: number;
    radius?: number;
    workSpeed?: number;
    depth?: number;
    workDepth?: number;
    plungingSpeed?: number;
    constructor () {
      this.name = 'Perimeter'
      this.point = {
        x: undefined,
        y: undefined
      }
      this.length = undefined
      this.width = undefined
      this.radius = undefined
      this.workSpeed = undefined
      this.depth = undefined
      this.workDepth = undefined
      this.plungingSpeed = undefined
    }
}

type InputAction = InputDrill | InputPocket | InputPerimeter | InputContour

enum InputType {
    InputDrill,
    InputPocket,
    InputPerimeter,
    InputContour,
    InputAction
}

export { InputType }
export type { InputPoint, InputDrill, InputPocket, InputPerimeter, InputContour, InputAction }
