import { Curve } from '@/types/geometry'
import { Groove, PartialGroove } from '@/types/groove'

export class GrooveHelper {
  static * partialGroove (groove: Groove): Generator<PartialGroove> {
    let actualDepth = 0
    let lastActualDepth = 0
    do {
      lastActualDepth = actualDepth
      actualDepth = Math.min(groove.workDepth + actualDepth, groove.depth)
      const newCurveChain: Array<Curve> = []
      for (const fullDepthCurve of groove.curveChain) {
        // 1-1 hach for -0
        const newStartZ = fullDepthCurve.start.z !== fullDepthCurve.end.z ? -lastActualDepth + 1 - 1 : -actualDepth + 1 - 1
        const newEndZ = -actualDepth + 1 - 1
        newCurveChain.push({
          name: fullDepthCurve.name,
          description: fullDepthCurve.description,
          start: { x: fullDepthCurve.start.x, y: fullDepthCurve.start.y, z: newStartZ },
          end: { x: fullDepthCurve.end.x, y: fullDepthCurve.end.y, z: newEndZ }
        })
      }
      yield {
        depth: actualDepth - lastActualDepth,
        plungingSpeed: groove.plungingSpeed,
        width: groove.width,
        workSpeed: groove.workSpeed,
        curveChain: newCurveChain
      }
    } while (actualDepth < groove.depth)
  }
}
