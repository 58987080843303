import { Point } from '@/types/geometry'
import { Head } from '@/types/groove'

export class ElementaryMove {
    private headPosition: Point
    private moveFeedRate: number
    private drillFeedRate: number
    private headActualFeedRate: number
    constructor (head: Head) {
      this.headPosition = head.actualLocation
      this.moveFeedRate = head.feedRate
      this.drillFeedRate = head.feedRate
      this.headActualFeedRate = head.actualFeedRate
    }

    public getHeadPosition (): Point {
      return this.headPosition
    }

    public setMoveFeedRate (moveFeedRate: number): void {
      this.moveFeedRate = moveFeedRate
    }

    public setDrillFeetRate (drillFeedRate: number): void {
      this.drillFeedRate = drillFeedRate
    }

    public circleSegment (point: Point, clockwise: boolean, radius: number): string {
      this.headPosition.x = point.x
      this.headPosition.y = point.y
      let feedRate = ''
      if (this.headActualFeedRate !== this.moveFeedRate) {
        feedRate = ` F${this.moveFeedRate}`
        this.headActualFeedRate = this.moveFeedRate
      }
      const command = clockwise ? 'G02' : 'G03'
      return `${command} X${point.x} Y${point.y} R${radius}` + feedRate
    }

    public lineSegment (point: Point): string {
      const command = 'G01'
      const xCoordinate = point.x !== this.headPosition.x ? `X${point.x}` : ''
      const yCoordinate = point.y !== this.headPosition.y ? `Y${point.y}` : ''
      let feedRate = ''
      if (this.headActualFeedRate !== this.moveFeedRate) {
        feedRate = `F${this.moveFeedRate}`
        this.headActualFeedRate = this.moveFeedRate
      }
      this.headPosition.x = point.x
      this.headPosition.y = point.y
      return [command, xCoordinate, yCoordinate, feedRate].filter((val) => val !== '').join(' ')
    }

    public quickMove (point: Point): string {
      this.headPosition.x = point.x
      this.headPosition.y = point.y
      return `G00 X${point.x} Y${point.y}`
    }

    public pullOutHead (): string {
      this.headPosition.z = 2
      return 'G00 Z2'
    }

    public plungeDrill (point: Point): string {
      this.headPosition.z = point.z
      this.headActualFeedRate = this.drillFeedRate
      return `G01 Z${this.headPosition.z} F${this.drillFeedRate}`
    }
}
