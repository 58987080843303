<template>
    <div class="row">
      <div class="col-2">
        <h6>Dostupné operace</h6>
        <draggable
          class="dragArea list-group"
          :list="availableOperation"
          group="people"
          @change="refresh"
          itemKey="name"
        >
          <template #item="{ element }">
            <div class="list-group-item">

              <img v-if="element.name === 'Obvod'" v-bind:src="require('@/assets/icons/obvod.svg')" class="" alt="Obvod" style="width: 100%;">
              <img v-if="element.name === 'Kapsa'" v-bind:src="require('@/assets/icons/kapsa.svg')" class="" alt="Kapsa" style="width: 100%;">
              <img v-if="element.name === 'Kontura'" v-bind:src="require('@/assets/icons/kontura.svg')" class="" alt="Kontura" style="width: 100%;">
              <img v-if="element.name === 'Vrtání'" v-bind:src="require('@/assets/icons/vrtani.svg')" class="" alt="Vrtání" style="width: 100%;">
              {{ element.name }}
            </div>
          </template>
        </draggable>
      </div>
      <div class="col-1 text-center">
        <i class="bi bi-arrow-right"></i>
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-9">
            <h6>Poslopnost operací</h6>
          </div>
          <div class="col-3 d-flex align-items-end justify-content-end">
            <button @click="removeAll" type="button" class="btn btn-danger">Smazat vše</button>
          </div>
        </div>
        <div class="row">
          <div class="col-11"><br></div>
        </div>
        <draggable
          class="dragArea list-group"
          :list="operationSequence"
          group="people"
          @change="refresh"
          itemKey="name"
        >
          <template #item="{ element, index }">
            <div class="list-group-item">
              <div  v-if="element.type === 0 && showEmptyArea()" class="row bg-light">
                <div class="col"><h2>{{ element.name }}</h2></div>
              </div>
              <div v-if="element.type !== 0" class="row">
                <div class="col-11">{{ element.name }}</div>
                <div class="col-1 d-flex align-items-end justify-content-end">
                  <button class="btn btn-sm btn-danger bi bi-x-lg float-right" @click="removeAt(index)"></button>
                </div>
              </div>
              <div v-if="element.type !== 0" class="row">
                <div class="col-11"><br></div>
              </div>
              <div v-if="(element.type === 1 || element.type === 2)" class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Délka</span>
                <input v-model="element.input.length" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Šířka</span>
                <input v-model="element.input.width" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Radius</span>
                <input v-model="element.input.radius" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Hloubka</span>
                <input v-model="element.input.depth" type="number" class="form-control">
              </div>
              <div v-if="element.type === 1 || element.type === 2" class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Přísuv</span>
                <input v-model="element.input.workDepth" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Zanoření F</span>
                <input v-model="element.input.plungingSpeed" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Pracovní F</span>
                <input v-model="element.input.workSpeed" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">X</span>
                <input v-model="element.input.point.x" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Y</span>
                <input v-model="element.input.point.y" type="number" class="form-control">
              </div>
              <div v-if="element.type === 3" class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Hloubka</span>
                <input v-model="element.input.depth" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Přísuv</span>
                <input v-model="element.input.workDepth" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Zanoření F</span>
                <input v-model="element.input.plungingSpeed" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Pracovní F</span>
                <input v-model="element.input.workSpeed" type="number" class="form-control">
              </div>
              <div v-if="element.type === 3" >
                <div v-for="point in element.input.points" :key="point.x" class="input-group">
                  <span class="input-group-text" id="basic-addon1">X</span>
                  <input type="number" class="form-control" v-model="point.x">
                  <span class="input-group-text" id="basic-addon1">Y</span>
                  <input type="number" class="form-control" v-model="point.y">
                </div>
                <button @click="addPoint(index)" type="button" class="btn btn-success">Přidat bod</button>
              </div>
              <div v-if="element.type === 4" class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Hloubka</span>
                <input v-model="element.input.depth" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Přísuv</span>
                <input v-model="element.input.workDepth" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Zanoření F</span>
                <input v-model="element.input.plungingSpeed" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">X</span>
                <input v-model="element.input.point.x" type="number" class="form-control">
                <span class="input-group-text" id="basic-addon1">Y</span>
                <input v-model="element.input.point.y" type="number" class="form-control">
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </template>
<script lang="ts">

import { defineComponent } from 'vue'
import draggable from '../../node_modules/vuedraggable'
import { EmptyInputDrill, EmptyInputPocket, EmptyInputPerimeter, EmptyInputContour } from '@/types/inputAction'
import { Action } from '@/types/action'
import { ActionFactory } from '@/service/ActionFactory'
import { AvailableOperation } from '@/types/availableOperation'
import store from '@/store'

const availableOperation: Array<AvailableOperation> = [
  { name: 'Obvod', type: 1, input: new EmptyInputPerimeter() },
  { name: 'Kapsa', type: 2, input: new EmptyInputPocket() },
  { name: 'Kontura', type: 3, input: new EmptyInputContour() },
  { name: 'Vrtání', type: 4, input: new EmptyInputDrill() }]

const operationSequence: Array<AvailableOperation> = [{ name: 'Přetáhni sem', type: 0, input: new EmptyInputDrill() }]

export default defineComponent({
  name: 'two-lists',
  display: 'Two Lists',
  order: 1,
  components: {
    draggable
  },
  data () {
    return {
      availableOperation,
      operationSequence
    }
  },
  watch: {
    operationSequence: {
      handler () {
        const actionFactory = new ActionFactory()
        const validatedSequence: Array<Action> = []
        window.console.log('start')
        let arrayResult = false
        for (const part of this.operationSequence) {
          if (part.type === 0) { // skip empty drop area
            continue
          }
          if (!this.checkSingleInput(part, actionFactory)) {
            arrayResult = false
            break
          } else {
            arrayResult = true
            validatedSequence.push(actionFactory.create())
          }
        }
        console.log(arrayResult)
        console.log(validatedSequence)
        if (arrayResult === true) {
          store.commit('addActions', validatedSequence)
        } else {
          store.commit('flushActions')
        }
        window.console.log('stop')
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    showEmptyArea () {
      return this.operationSequence.length === 1
    },
    checkSingleInput (input: any, actionFactory: any) {
      console.log(input)
      let result = false
      switch (input.type) {
        case 1: {
          const perimeter = input.input
          result = actionFactory.setPerimeter(perimeter)
        }
          break
        case 2: {
          const pocket = input.input
          result = actionFactory.setPocket(pocket)
        }
          break
        case 3: {
          const contour = input.input
          result = actionFactory.setContour(contour)
        }
          break
        case 4: {
          const drill = input.input
          result = actionFactory.setDrill(drill)
        }
          break
      }
      return result
    },
    removeAt: function (idx: any) {
      this.operationSequence.splice(idx, 1)
    },
    removeAll: function () {
      this.operationSequence = [{ name: 'Přetáhni sem', type: 0, input: new EmptyInputDrill() }]
    },
    addPoint: function (index: any) {
      this.operationSequence[index].input.points.push({ x: 0, y: 0 })
    },
    refresh: function (evt: any) {
      // window.console.log(evt)
      this.availableOperation = [
        { name: 'Obvod', type: 1, input: new EmptyInputPerimeter() },
        { name: 'Kapsa', type: 2, input: new EmptyInputPocket() },
        { name: 'Kontura', type: 3, input: new EmptyInputContour() },
        { name: 'Vrtání', type: 4, input: new EmptyInputDrill() }
      ]
    },
    restoreSequence () {
      for (const action of store.state.actionSequence) {
        console.log(action.name)
        switch (action.name) {
          case 'Pocket':
            this.operationSequence.push({ name: 'Kapsa', type: 2, input: action })

            break
          case 'Perimeter':
            this.operationSequence.push({ name: 'Obvod', type: 1, input: action })

            break
          case 'Drill':
            this.operationSequence.push({ name: 'Vrtání', type: 3, input: action })

            break
          case 'Contour':
            this.operationSequence.push({ name: 'Kontura', type: 4, input: action })

            break
        }
      }
    }
  }
})
</script>
