import { Action } from '@/types/action'
import { Head } from '@/types/groove'
import { createStore } from 'vuex'

const action: Array<Action> = []
const gCode: Array<string> = []
const head: Head = {
  actualFeedRate: 0,
  actualLocation: { x: 0, y: 0, z: 50 },
  feedRate: 0,
  moveRate: 0,
  nullPoint: { x: 0, y: 0, z: 50 },
  width: 1
}
export default createStore({
  state: {
    actionSequence: action,
    gCode: gCode,
    head: head
  },
  getters: {
  },
  mutations: {
    addActions (state, actions: Array<Action>) {
      state.actionSequence = actions
    },
    flushActions (state) {
      state.actionSequence = []
    },
    updateGCode (state, gCode: Array<string>) {
      state.gCode = gCode
    },
    updateHead (state, head: Head) {
      state.head = head
    }
  },
  actions: {
  },
  modules: {
  }
})
