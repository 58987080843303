import { CurveHelper, MoveType } from '@/helper/CurveHelper'
import { Curve, Point } from '@/types/geometry'
import { Head, PartialGroove } from '@/types/groove'

export class CurveIterator {
    private grooves: Array<PartialGroove> = []
    private firstGrooveStart: Point | null = null

    public add (groove: PartialGroove) {
      this.grooves.push(groove)
      if (this.firstGrooveStart === null) {
        this.firstGrooveStart = groove.curveChain[0].start
      }
    }

    public * iterateCurve (head: Head): Generator<[Curve, number, MoveType]> {
      const firstGrooveStart: Point = this.firstGrooveStart ?? { x: 0, y: 0, z: 0 }
      let lastCurve: Curve = {
        name: 'Line',
        description: {},
        start: { x: 0, y: 0, z: 50 },
        end: { x: firstGrooveStart.x, y: firstGrooveStart.y, z: 50 }
      }
      yield [lastCurve, head.moveRate, 'Horizontal']
      for (const groove of this.grooves) {
        for (const curve of groove.curveChain) {
          const curves: Array<Curve> = [curve]
          for (const [jointCurve, moveType] of CurveHelper.join(lastCurve, curve, 2)) {
            switch (moveType) {
              case 'Up':
                yield [jointCurve, groove.plungingSpeed, moveType]
                break
              case 'Down':
                yield [jointCurve, groove.plungingSpeed, moveType]
                break
              case 'Horizontal':
                yield [jointCurve, groove.workSpeed, moveType]
                break
            }
          }
          lastCurve = curve
          const isUp = curve.start.z < curve.end.z
          const isDown = curve.start.z > curve.end.z
          const isHorizontal = curve.start.z === curve.end.z
          const resolvedMoveType: MoveType = isHorizontal ? 'Horizontal' : (isDown ? 'Down' : 'Up')
          yield [curve,
            isUp || isDown ? groove.plungingSpeed : groove.workSpeed,
            resolvedMoveType
          ]
        }
      }
    }
}
