<template>
  <ul class="nav nav-tabs">
    <li @click="setActiveTab('input')" class="nav-item">
      <a :class="getTabClass('input')" aria-current="page" href="#">Vstupy</a>
    </li>
    <li @click="setActiveTab('result')" class="nav-item">
      <a :class="getTabClass('result')" href="#">Výsledek</a>
    </li>
  </ul>
    <div  v-if="isActiveTab('input')" class="row">
    <div class="col-xs-12">
    <form>
      <div class = mb-3>
        <h6>Vlastnosti Frézy</h6>
        <div class="input-group">
        <span class="input-group-text">Průměr frézy</span>
        <input v-model="head.width" type="number" step="0.01" class="form-control" id="diameter" placeholder="mm">
        <span class="input-group-text">Rychloposuv F</span>
        <input v-model="head.moveRate" type="number" class="form-control" id="fastShift">
        <span class="input-group-text">Otáčky S</span>
        <input v-model="head.feedRate" type="number" class="form-control" id="rotateCount" placeholder="1/s">
      </div>
      </div>
      <div class="mb-3">
        <h6>Rozměr polotovaru</h6>
        <div class="input-group">
          <span class="input-group-text">x<sub>min</sub></span>
          <input type="number" class="form-control" placeholder="mm">
          <span class="input-group-text">y <sub>min</sub> </span>
          <input type="number" class="form-control" placeholder="mm">
          <span class="input-group-text">z <sub>min</sub> </span>
          <input type="number" class="form-control" placeholder="mm">
        </div>
        <div class="input-group">
          <span class="input-group-text">x <sub>max</sub></span>
          <input type="number" class="form-control" placeholder="mm">
            <span class="input-group-text">y <sub>max</sub></span>
            <input type="number" class="form-control" placeholder="mm">
            <span class="input-group-text">z <sub>max</sub> </span>
            <input type="number" class="form-control" placeholder="mm">
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-if="isActiveTab('input')" class="row">
    <operations></operations>
  </div>
  <div  v-if="isActiveTab('result')" class="row">
    <div class="col-xs-12">
      <div class="container mt-4">
        <div class="bg-light">
          <h6 class="text-primary text-center">G Code</h6>
          <p v-bind:key="command" v-for="command in commands">{{ command }}</p>
        </div>
      </div>
      <div class="container mt-4">
        <div class="bg-light">
          <h6 class="text-primary text-center">Výsledný tvar</h6>
          <div class="bd-example">
<svg class="bd-placeholder-img bd-placeholder-img-lg img-fluid" width="100%" height="250" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Responsive image" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Responsive image</text></svg>
</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import './assets/style.scss'
import operations from './components/Operations.vue'
import store from './store'
import { GCodeService } from './service/GCodeService'
import { GrooveBuilderService } from './service/GrooveBuilder'
import { Head } from './types/groove'
import { ElementaryMove } from './service/GCode/ElementaryMove'
import { CurveIterator } from './service/CurveIterator'

export default defineComponent(
  {
    name: 'app',
    components: {
      operations
    },
    data () {
      return {
        currentTab: 'input',
        head: store.state.head
      }
    },
    methods: {
      isActiveTab (tab: string) {
        return tab === this.currentTab
      },
      setActiveTab (tab: string) {
        this.currentTab = tab
      },
      getTabClass (tab: string) {
        return tab === this.currentTab ? 'nav-link active' : 'nav-link'
      }
    },
    watch: {
      head: {
        handler () {
          store.commit('updateHead', this.head)
        },
        deep: true
      }
    },
    computed: {
      commands () {
        store.commit('updateGCode', [])
        const head: Head = store.state.head
        const elementaryMove = new ElementaryMove(head)
        const grooveBuilder = new GrooveBuilderService(head)
        const gCodeService = new GCodeService(elementaryMove, new CurveIterator())
        const grooves = []
        for (const action of store.state.actionSequence) {
          const groove = grooveBuilder.getGroove(action)
          grooves.push(groove)
        }
        console.log('ha')
        console.log(grooves)
        const commands: Array<string> = gCodeService.generateGCode(grooves, head).commands
        console.log('g code start')
        console.log(commands)
        console.log('g code end')
        store.commit('updateGCode', commands)
        return store.state.gCode
      }
    }
  }
)
</script>

<style lang="scss">

</style>
